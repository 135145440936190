<template>
    <div class="row">
        <div :class="displayBlock ? 'col-12' : 'col-12 col-lg-4'">
            <b-form-group :label="$t('keys.color')">
                <div style="width: 100%; border-radius: 5px; padding: 4px;" class="border">
                    <b-dropdown id="dropdown-color-selector" :style="backgroundStyle" variant='none' style="width:100%;">
                        <color-select @input="e=> $emit('input', e)"></color-select>
                    </b-dropdown>
                </div>
            </b-form-group>
        </div>
        <div v-if="showPreview" :class="displayBlock ? 'col-12' : 'col-12 col-lg-8'">
            <b-form-group :label="$t('general.preview')">
                <div class="colorBorder" :style="backgroundStyle" style="margin-top:5px; height: 38px; border-radius: 10px; display:flex; justify-content: center; align-items: center; font-size: 15px;"><span :style="textStyle"> {{ productName }} </span></div>
            </b-form-group>
        </div>
    </div>
</template>

<script>
    import { BDropdown, BFormGroup } from 'bootstrap-vue'
    import colorSelect from '@/views/components/ColorSelect.vue'

    export default {
        components: {
            BDropdown,
            BFormGroup,
            colorSelect
        },
        computed: {
            backgroundStyle() {
                if (this.value === '') {
                    return 'background-color: #545454'
                } else return `background-color: ${this.value};`
            },
            textStyle() {
                if (this.value === '') {
                    return 'color: #E1E1E1'
                } else return `color: ${this.getValidContrast(this.value)}`
            }
        },
        props: {
            value: {
                type: String
            },
            productName: {
                default: 'Lorem Ipsum'
            },
            displayBlock: {
                type: Boolean,
                default: false
            },
            showPreview: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            getValidContrast(background) {
                const rgb1 = this.hexToRgb(background)
                const rgbWhite = this.hexToRgb('#ffffff')
                const contrastWhite = this.contrast(rgbWhite, rgb1)

                const rgbBlack = this.hexToRgb('#000000')
                const contrastBlack = this.contrast(rgbBlack, rgb1)

                if (contrastBlack > contrastWhite) {
                    return '#000000'
                }

                return '#FFFFFF'
            },
            hexToRgb(hex) {
                // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
                const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
                hex = hex.replace(shorthandRegex, function(m, r, g, b) {
                    return r + r + g + g + b + b
                })

                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
                return result ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                } : null
            },
            contrast(rgb1, rgb2) {

                const lum1 = this.luminance(rgb1['r'], rgb1['g'], rgb1['b'])
                const lum2 = this.luminance(rgb2['r'], rgb2['g'], rgb2['b'])

                const brightest = Math.max(lum1, lum2)
                const darkest = Math.min(lum1, lum2)
                return (brightest + 0.05) / (darkest + 0.05)
            },
            luminance(r, g, b) {

                if (r === null || r === undefined || g === null || g === undefined || b === null || b === undefined) {
                    return 0
                }

                const RED = 0.2126
                const GREEN = 0.7152
                const BLUE = 0.0722

                const GAMMA = 2.4


                const a = [r, g, b].map((v) => {
                    v /= 255
                    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA)
                })
                return (a[0] * RED) + (a[1] * GREEN) + (a[2] * BLUE)
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#dropdown-color-selector .dropdown-toggle::after{
  mix-blend-mode: difference;
}
#dropdown-color-selector .dropdown-toggle{
  //padding-left: 10px;
}
</style>
<style scoped>
.light-layout .colorBorder {
    border: 1px solid #ebe9f1 !important;
}

.dark-layout .colorBorder {
    border: 1px solid #9a9a9a !important;
}
</style>

